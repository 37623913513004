export default
[
    {
        path: "refund-payment-add",
        component: () => import("@/pages/parameter/refund-payment/refund-payment-add"),
        meta: {
            title: "退款参数添加"
        }
    },
    {
        path: "refund-payment-edit",
        component: () => import("@/pages/parameter/refund-payment/refund-payment-edit"),
        meta: {
            title: "退款参数编辑"
        }
    },
    {
        path: "refund-payment-list",
        component: () => import("@/pages/parameter/refund-payment/refund-payment-list"),
        meta: {
            title: "退款账户参数",
            icon: "el-icon-postcard",
        }
    },
]