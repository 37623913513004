import self from "@/main";

export default {
    preSale: 1,
    afterSale: 2,
    cascader: 3, //级联选择
    appointmentProjects: "预约项目",
    first_recordType: "客资列表",
    //所有员工
    queryEmpIds() {
        return self.$axios({
            method: "GET",
            url: "/select/empIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //所有员工
    queryEmpIdsByTenantCrop(tenantCrop) {
        return self.$axios({
            method: "GET",
            url: "/select/empIds",
            params: {
                tenantCrop: tenantCrop,
            }
        })
    },
    //预约项目
    queryAppProjectsIds(projectType, preAfterSale) {
        return self.$axios({
            method: "GET",
            url: "/select/appProjects",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
                projectsType: projectType,
                preAfterSale: preAfterSale,
            }
        })
    },
    //预约项目 根据预约类型和公司id
    queryProjectsByType(projectsType) {
        return self.$axios({
            method: "GET",
            url: "/select/queryProjectsByType",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
                projectsType: projectsType,
            }
        })
    },
    //店铺
    queryShopIds() {
        return self.$axios({
            method: "GET",
            url: "/select/shopIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //有效店铺
    shopIdsIsValid() {
        return self.$axios({
            method: "GET",
            url: "/select/shopIdsIsValid",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //渠道
    querySourceIds(type) {
        return self.$axios({
            method: "GET",
            url: "/select/sourceIds",
            params: {
                type: type,
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //客服
    queryServiceIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/serviceIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //折扣
    queryDiscountIds: function () {
        return self.$axios({
            method: "GET",
            url: '/select/discountIds',
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //培训课程
    queryTrainCourseIds: function () {
        return self.$axios({
            method: 'get',
            url: '/select/trainCourseIds',
            params: {
                isValid: 1,
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //培训员工
    queryTrainUserIds: function (companyId) {
        return self.$axios({
            method: 'get',
            url: '/select/trainUserIds',
            params: {
                companyId: companyId,
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //查询项目
    queryProjectsIds: function (type) {
        return self.$axios({
            method: 'get',
            url: '/select/projectsIds',
            params: {
                projectsType: type,
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //收款方式
    queryPaymentIds: function () {
        return self.$axios({
            method: 'get',
            url: '/select/paymentIds',
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //职位
    queryRoleIds: function () {
        return self.$axios({
            method: "get",
            url: "/select/roleIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //客资意愿
    queryGradeIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/gradeIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //客资状态
    queryStateIds: function (type, stateType) {
        return self.$axios({
            method: "GET",
            url: "/select/stateIds",
            params: {
                type: type,
                stateType: stateType,
            }
        })
    },
    //预约状态

    //线上订单收款项目
    queryOnlineOrderProceedsName: function () {
        return self.$axios({
            method: "get",
            url: "/select/onlineProcessProjectIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        });
    },
    //收款人
    queryPayeeIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/payeeIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //退款方式
    refundPaymentIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/refundPaymentId",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //退款人
    refundPeopleIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/empIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //查询所有1级渠道
    querySourceOneLevelIds() {
        return self.$axios({
            method: "GET",
            url: "/select/sourceOneLevelIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //根据店铺Id查询位置
    queryPositionIdsByShopId(shopId) {
        return self.$axios({
            method: "GET",
            url: "/select/positionIdsByShopId",
            params: {
                shopId: shopId,
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //礼服师
    queryDressIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/dressIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //化妆师
    queryCosmeticsIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/cosmeticsIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //根据店铺ID查询房间
    queryRoomIdsByShopId: function (shopId) {
        return self.$axios({
            method: "GET",
            url: "/select/roomIdsByShopId",
            params: {
                shopId: shopId,
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //订单收款项目
    queryOrderProceedsProjects() {
        return self.$axios({
            method: "get",
            url: "/select/orderProceedsProjectId",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //追加收款项目
    queryAddProceedProjects() {
        return self.$axios({
            method: "get",
            url: "/select/addProceedsProjectsId",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //款式类型
    queryStyleTypeIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/styleTypeIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //根据店铺查询位置
    queryPositionIdsByShop: function (shopId) {
        return self.$axios({
            method: "GET",
            url: "/select/positionIdsByShopId",
            params: {
                shopId: shopId,
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //查询标签
    queryLabelIds: function (labelType) {
        return self.$axios({
            method: "GET",
            url: "/select/labelIds",
            params: {
                labelType: labelType,
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //查询品牌
    queryBrandIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/brandIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //查询品牌名称
    queryBrandNames: function () {
        return self.$axios({
            method: "GET",
            url: "/select/brandNames",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //查询操作
    queryOperationIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/operationIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    // 查询项目列表
    queryProjectsList(projectsType, page, limit) {
        return self.$axios({
            method: "GET",
            url: "/projects/projectsList",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
                projectsType: projectsType,
                page: page,
                limit: limit,
            }
        })
    },
    //查询图片类型
    queryStyleImageTypes(state) {
        return self.$axios({
            method: "GET",
            url: "/select/styleImageTypeIds",
            params: {
                state:state,
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    //查询品牌等级
    queryBrandGradeIds() {
        return self.$axios({
            method: "GET",
            url: "/select/brandGradeIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    // 查询婚纱尺寸
    queryClothesSizeIds() {
        return self.$axios({
            method: "GET",
            url: "/select/clothesSizeIds",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
            }
        })
    },
    // 查询款式价格类型
    queryStylePriceType() {
        return self.$axios({
            method: "GET",
            url: "/select/stylePriceType",
            params: {

            }
        })
    },
    // 查询公司
    queryTenantIds() {
        return self.$axios({
            method: "GET",
            url: "/select/tenantIds",
        })
    },
    //查询公司编号
    queryTenantCrops() {
        return self.$axios({
            method: "GET",
            url: "/select/tenantCrops",
        })
    },
    //查询收藏记录
    queryRecord(type) {
        return self.$axios({
            method: "GET",
            url: "/record/queryRecordList",
            params: {
                tenantCrop: localStorage.getItem("tenantCrop"),
                empId: JSON.parse(localStorage.getItem("emp")).id,
                type: type,
            }
        })
    },
    //查询标签库
    queryLibLabelIds: function (labelType) {
        return self.$axios({
            method: "GET",
            url: "/select/libLabelIds",
        })
    },
    //查询款式类型库
    queryLibStyleTypeIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/libStyleTypeIds",
        })
    },
    //查询款式图片类型库
    queryLibStyleImageTypeIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/libStyleImageTypeIds",
        })
    },
    //查询款式品牌库
    queryLibBrandIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/libBrandIds",
        })
    },
    //查询款式系列库
    queryLibSeriesIds: function (libBrandId) {
        return self.$axios({
            method: "GET",
            url: "/select/libSeriesIds",
            params:{
                libBrandId:libBrandId
            }
        })
    },
    //查询款式系列编号库
    queryLibSeriesNumIds: function (libBrandId,libSeriesId) {
        return self.$axios({
            method: "GET",
            url: "/select/libSeriesNumIds",
            params:{
                libBrandId:libBrandId,
                libSeriesId:libSeriesId
            }
        })
    },
    // 查询产品等级
    queryProductGradeIds() {
        return self.$axios({
            method: "get",
            url: "/select/productGradeIds",
        })
    },
    //查询商城款式类型
    queryStoreStyleTypeIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/storeStyleTypeIds",
        })
    },
    //查询商城款式品牌
    queryStoreBrandIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/storeBrandIds",
        })
    },
    //查询商城款式系列
    queryStoreSeriesIds: function (storeBrandId) {
        return self.$axios({
            method: "GET",
            url: "/select/storeSeriesIds",
            params:{
                storeBrandId:storeBrandId
            }
        })
    },
    //查询款式系列编号库
    queryStoreSeriesNumIds: function (storeBrandId,storeSeriesId) {
        return self.$axios({
            method: "GET",
            url: "/select/storeSeriesNumIds",
            params:{
                storeBrandId:storeBrandId,
                storeSeriesId:storeSeriesId
            }
        })
    },
    //查询商城款式标签
    queryStoreLabelIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/storeLabelIds",
        })
    },
    //查询商城款式图片类型
    queryStoreStyleImageTypeIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/storeStyleImageTypeIds",
        })
    },
    // 查询账户类型
    queryAccountTypeIds() {
        return self.$axios({
            method: 'GET',
            url: '/select/accountTypeIds',
            params: {
                tenantCrop: localStorage.getItem('tenantCrop')
            }
        })
    },
    // 查询账户,参数 0-非营收 1-营收 不填查询全部
    queryAccountIds(type) {
        return self.$axios({
            method: 'GET',
            url: '/select/accountIds',
            params: {
                type: type,
                tenantCrop: localStorage.getItem('tenantCrop')
            }
        })
    },
    // 查询报销收款方式
    querySpendPeopleIds() {
        return self.$axios({
            method: 'GET',
            url: '/select/spendPeopleIds',
            params: {
                tenantCrop: localStorage.getItem('tenantCrop')
            }
        })
    }
}