export default
[
    {
        path: 'size-list',
        component: () => import("@/pages/parameter/size/size-list"),
        meta: {
            title: "婚纱尺码参数",
            icon: "el-icon-reading"
        }
    }
]