import Vue from 'vue'
import Router from 'vue-router'
import store from "@/store";
import self from "@/main"

import main from "@/router/main"


Vue.use(Router)

const router = new Router({
    mode: 'hash',
    routes: [
        ...main,
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

router.afterEach(() => {
    //到期时间
    let loginTimeOut=Number(localStorage.getItem("loginTimeOut"));
    //当前时间
    let nowDate=Math.round(new Date().getTime()/1000);

    // 已登录 如果当前时间<到期时间放行 屏蔽login register android-down 放行其他
    if (localStorage.getItem("tenantCrop") !== null && nowDate < loginTimeOut) {
        if (self.$route.path === '/' || self.$route.path === "/login"||self.$route.path==="/register"||self.$route.path==="/android-down"
        ||self.$route.path==="/forgot-password") {
            router.push(store.state.activeIndex)
        }
    // 未登录 放行login register android-down 其他全部走index
    } else {
        if (self.$route.path === "/login"||self.$route.path==="/register"||self.$route.path==="/android-down"
        ||self.$route.path==="/forgot-password") {
            return
        }
        if (self.$route.path !== '/') {
            router.push('/')
        }
    }
    window.scrollTo(0, 0);

});

export default router
