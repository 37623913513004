import shop from "@/router/sys-manage/parameter/shop"   //店铺
import source from "@/router/sys-manage/parameter/source"   //来源
import state from "@/router/sys-manage/parameter/state"   //状态
import grade from "@/router/sys-manage/parameter/grade"   //意愿
import projects from "@/router/sys-manage/parameter/projects"   //意愿
import discount from "@/router/sys-manage/parameter/discount"   //意愿
import warning from "@/router/sys-manage/parameter/warning"   //意愿
import operation from "@/router/sys-manage/parameter/operation"   //操作
import refundPayment from "@/router/sys-manage/parameter/refund-payment"   //退款参数
import size from "@/router/sys-manage/parameter/size" //婚纱尺寸
import collection from "@/router/sys-manage/parameter/collection" //收款进度参数
export default
{
    path: "parameter",
    component: () => import("@/layouts/item"),
    meta: {
        title: "参数管理"
    },
    children: [
        ...shop,
        ...source,
        ...state,
        ...grade,
        ...projects,
        ...discount,
        ...warning,
        ...operation,
        ...refundPayment,
        ...size,
        ...collection,
        {
            path: "style-image-type",
            component: () => import("@/pages/parameter/style-image-type/style-image-type-list"),
            meta: {
                title: "款式图片类型",
                icon: "el-icon-picture-outline",
            }
        },
        {
            path: "schedule-rule",
            component: () => import("@/pages/parameter/schedule-rule/schedule-rule"),
            meta: {
                title: "默认档期跨度",
                icon: "el-icon-date",
            }
        }
    ]
}
