export default
{
    path: "purchases",
    component: () => import("@/layouts/item"),
    meta: {
        title: "采购管理"
    },
    children: [
        {
            path: "store-order-list",
            component: () => import("@/pages/purchases/order/store-order-list"),
            meta: {
                title: "采购列表"
            }
        },
    ]
}

