import self from "@/main";
let COS =require('cos-js-sdk-v5');

export default {
    tenantCrop: localStorage.getItem("tenantCrop"),

    clothesImage: "clothes",
    orderImage: "order",
    commImage:"communicate",
    expendImage: "expendImage",
    styleVideo:'styleVideo',
    brandImage:'brandImage',

    getRegion(){ return 'ap-shanghai'},

    getBucketName(name){
        switch (name){
            case 'clothes':
                return 'clothes-image-1304365928';
            case 'styleVideo':
                return 'style-video-1304365928';
            case 'brandImage':
                return 'brand-image-1304365928';
            case 'expendImage':
                return 'expend-image-1304365928';
        }
    },


    clothesImageUpload: function (file) {
        const data = new FormData();
        data.append('file',file)
        data.append("tenant",this.tenantCrop)
        data.append("imageType",this.clothesImage)
        return self.$axios({
            method: "post",
            url: '/upload/uploadImage', //用于文件上传的服务器端请求地址
            data: data,
        })
    },
    orderImageUpload: function (file) {
        const data = new FormData();
        data.append('file',file)
        data.append("tenant",this.tenantCrop)
        data.append("imageType",this.orderImage)
        return self.$axios({
            method: "post",
            url: '/upload/uploadImage', //用于文件上传的服务器端请求地址
            data: data,
        })
    },
    commImageUpload: function (file) {
        const data = new FormData();
        data.append('file',file)
        data.append("tenant",this.tenantCrop)
        data.append("imageType",this.commImage)
        return self.$axios({
            method: "post",
            url: '/upload/uploadImage', //用于文件上传服务器端请求地址
            data: data,
        })
    },
    expendImageUpload: function (file) {
        const data = new FormData();
        data.append("file", file)
        data.append("tenant", this.tenantCrop)
        data.append("imageType", this.expendImage)
        return self.$axios({
            method: "post",
            url: "/upload/uploadImage",
            data: data,
        })
    },

    //获取tencentCloud上传秘钥
    getTemporaryKey(bucket){
        return new COS({
            // getAuthorization 必选参数
            getAuthorization: function (options, callback) {
                //本地测试时删除/api
                let url = `/api/upload/getTemporaryKey/${bucket}`; // url替换成您自己的后端服务
                let xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.onload = function (e) {
                    let credentials;
                    let data = JSON.parse(e.target.responseText).data;
                    credentials = data.credentials;
                    if (!data || !credentials) {
                        return console.error('credentials invalid:\n' + JSON.stringify(data, null, 2))
                    }
                    callback({
                        TmpSecretId: credentials.tmpSecretId,
                        TmpSecretKey: credentials.tmpSecretKey,
                        SecurityToken: credentials.sessionToken,
                        // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
                        StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
                        ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
                    });
                };
                xhr.send();
            }
        });
    },

}
