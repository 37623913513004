import empManage from "@/router/sys-manage/emp-manage"   //用户管理
import parameter from "@/router/sys-manage/parameter"   //系统参数管理
import specialFunction from "@/router/sys-manage/special-function"
export default
{
    path: "/sys-manage",
    component: () => import("@/layouts/item"),
    meta: {
        title: "系统管理"
    },
    children: [
        empManage,
        parameter,
        specialFunction
    ]
}

