export default
{
    path: "account",
    component: () => import("@/layouts/item"),
    meta: {
        title: "账户管理"
    },
    children: [
        {
            path: "account-list",
            component: () => import("@/pages/spend/account/account-list"),
            meta: {
                title: "账户列表"
            }
        },
        {
            path: "bank-transfer",
            component: () => import("@/pages/spend/account/bank-transfer"),
            meta: {
                title: "转账"
            }
        },
        {
            path: "account-type-list",
            component: () => import("@/pages/spend/account/account-type-list"),
            meta: {
                title: "账户类型列表"
            }
        },
    ]
}