export default [
    {
        path: "mobile-view-list",
        component: () => import("@/pages/view/mobile-view/mobile-view-list"),
        meta: {
            title: "手机页面列表",
            icon: "el-icon-mobile-phone",
        }
    },
    {
        path: "mobile-button-list",
        component: () => import("@/pages/view/pc-view/pc-button-list"),
        meta: {
            title: "手机按钮列表",
            icon: "el-icon-mobile",
        }
    },
]