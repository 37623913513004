import self from "@/main";
// 主要用在让别的店铺查询我们的婚纱
export default {
    preSale: 1,
    afterSale: 2,
    cascader: 3, //级联选择
    appointmentProjects: "预约项目",
    first_recordType: "客资列表",
    //店铺
    queryShopIds() {
        return self.$axios({
            method: "GET",
            url: "/select/shopIds",
            params: {
                tenantCrop: "2a31c23a-c178-441614928053489",
            }
        })
    },
    //根据店铺Id查询位置
    queryPositionIdsByShopId(shopId) {
        return self.$axios({
            method: "GET",
            url: "/select/positionIdsByShopId",
            params: {
                shopId: shopId,
                tenantCrop: "2a31c23a-c178-441614928053489",
            }
        })
    },
    //款式类型
    queryStyleTypeIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/styleTypeIds",
            params: {
                tenantCrop: "2a31c23a-c178-441614928053489"
            }
        })
    },
    //查询标签
    queryLabelIds: function (labelType) {
        return self.$axios({
            method: "GET",
            url: "/select/labelIds",
            params: {
                labelType: labelType,
                tenantCrop: "2a31c23a-c178-441614928053489",
            }
        })
    },
    //查询品牌
    queryBrandIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/brandIds",
            params: {
                tenantCrop: "2a31c23a-c178-441614928053489",
            }
        })
    },
    //查询品牌名称
    queryBrandNames: function () {
        return self.$axios({
            method: "GET",
            url: "/select/brandNames",
            params: {
                tenantCrop: "2a31c23a-c178-441614928053489",
            }
        })
    },
    //查询图片类型
    queryStyleImageTypes(state) {
        return self.$axios({
            method: "GET",
            url: "/select/styleImageTypeIds",
            params: {
                state:state,
                tenantCrop: "2a31c23a-c178-441614928053489",
            }
        })
    },
    //查询品牌等级
    queryBrandGradeIds() {
        return self.$axios({
            method: "GET",
            url: "/select/brandGradeIds",
            params: {
                tenantCrop: "2a31c23a-c178-441614928053489",
            }
        })
    },
    // 查询婚纱尺寸
    queryClothesSizeIds() {
        return self.$axios({
            method: "GET",
            url: "/select/clothesSizeIds",
            params: {
                tenantCrop: "2a31c23a-c178-441614928053489",
            }
        })
    },
}