import work from "@/router/work"   //退款管理
import statistic from "@/router/statistic"   //退款管理
import sysManage from "@/router/sys-manage"   //退款管理
import tenantManage from "@/router/tenant-manage"   //退款管理
export default [
    {
        path: '/',
        component: () => import('@/pages/main/index'),
        meta: {
            title: '自述文件'
        },
    },
    {
        path: '/login',
        component: () => import('@/pages/main/login'),
        meta: {
            title: '登陆'
        },
    },
    {
        path: '/main',
        component: () => import('@/pages/main/main'),
        meta: {
            title: '主页'
        },
        redirect: '/home',
        children: [
            {
                path: "/home",
                component: () => import("@/pages/main/home"),
                meta: {
                    title: "首页"
                },
            },
            work,
            statistic,
            sysManage,
            tenantManage,
        ]
    },
    {
        path: '/register',
        component: () => import('@/pages/main/register'),
        meta: {
            title: '注册'
        },
    },
    {
        path: '/android-down',
        component: () => import('@/pages/main/android-down'),
        meta: {
            title: '安卓下载'
        },
    },
    {
        path: '/forgot-password',
        component: () => import('@/pages/main/forgot-password'),
        meta: {
            title: '忘记密码'
        },
    },
]
