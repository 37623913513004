export default [
    {
        path: "receive-exception-log",
        component: () => import("@/pages/tenant/log/receive-exception-log"),
        meta: {
            title: "对接错误日志"
        }
    },
    {
        path: "log-list",
        component: () => import("@/pages/tenant/log/log-list"),
        meta: {
            title: "日志"
        }
    },

]