export default
    {
        path: "customer",
        component: () => import("@/layouts/item"),
        meta: {
            title: "客资管理"
        },
        children: [
            {
                path: "cus-add",
                component: () => import("@/pages/customer/cus-add"),
                meta: {
                    title: "客资添加",
                    icon: "el-icon-circle-plus-outline",
                }
            },
            {
                path: "cus-list",
                component: () => import("@/pages/customer/cus-list"),
                meta: {
                    title: "客资列表",
                    icon: "el-icon-s-custom",
                }
            },
            {
                path: "cus-update",
                component: () => import("@/pages/customer/cus-update"),
                meta: {
                    title: "客资修改"
                }
            },
            {
                path: "comm-add",
                component: () => import("@/pages/customer/comm/comm-add"),
                meta: {
                    title: "沟通添加"
                }
            },
            {
                path: "comm-list",
                component: () => import("@/pages/customer/comm/comm-list"),
                meta: {
                    title: "客资沟通",
                    icon: "el-icon-phone-outline",
                }
            },
            {
                path: "label-list",
                component: () => import("@/pages/parameter/label/label-list"),
                meta: {
                    title: "标签列表",
                    icon: "el-icon-collection-tag",
                }
            },
        ]
    }

