
export default
{
    path: "clothes",
    component: () => import("@/layouts/item"),
    meta: {
        title: "婚纱管理"
    },
    children: [
        {
            path: "clothes-add",
            component: () => import("@/pages/clothes/clothes-add"),
            meta: {
                title: "婚纱添加"
            }
        },

        {
            path: "clothes-detail",
            component: () => import("@/pages/clothes/clothes-detail"),
            meta: {
                title: "婚纱详情"
            }
        },
        {
            path: "clothes-list",
            component: () => import("@/pages/clothes/clothes-list"),
            meta: {
                title: "婚纱列表",
                icon: "el-icon-reading",
            }
        },
        {
            path: "operation-add",
            component: () => import("@/pages/clothes/operation/operation-add"),
            meta: {
                title: "操作添加"
            }
        },
        {
            path: "operation-list",
            component: () => import("@/pages/clothes/operation/operation-list"),
            meta: {
                title: "婚纱足迹",
                icon: "el-icon-map-location",
            }
        },


    ]
}

