import self from "@/main";

export default {
    preSale: 1,
    afterSale: 2,
    cascader: 3, //级联选择
    //查询标签
    queryLabelNames: function (labelType) {
        return self.$axios({
            method: "GET",
            url: "/select/labelNames",
            params: {
                labelType: labelType,
            }
        })
    },
    //查询品牌
    queryBrandIds: function () {
        return self.$axios({
            method: "GET",
            url: "/select/brandIds",
        })
    },
    //查新品牌名称
    queryBrandNames: function () {
        return self.$axios({
            method: "GET",
            url: "/select/brandNames",
        })
    },
}