export default
{
    path: "refund",
    component: () => import("@/layouts/item"),
    meta: {
        title: "退款管理"
    },
    children: [
        {
            path: "refund-add",
            component: () => import("@/pages/refund/refund-add"),
            meta: {
                title: "退款添加"
            }
        },
        {
            path: "refund-edit",
            component: () => import("@/pages/refund/refund-edit"),
            meta: {
                title: "退款编辑"
            }
        },
        {
            path: "refund-list",
            component: () => import("@/pages/refund/refund-list"),
            meta: {
                title: "退款列表",
                icon: "el-icon-document-copy",
            }
        },

    ]
}

