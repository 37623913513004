export default
[
    {
        path: "grade-add",
        component: () => import("@/pages/parameter/grade/grade-add"),
        meta: {
            title: "意愿添加"
        }
    },
    {
        path: "grade-edit",
        component: () => import("@/pages/parameter/grade/grade-edit"),
        meta: {
            title: "意愿编辑"
        }
    },
    {
        path: "grade-list",
        component: () => import("@/pages/parameter/grade/grade-list"),
        meta: {
            title: "意愿程度",
            icon: "el-icon-s-marketing",
        }
    },
]