export default {
    path: "special-function",
    component: () => import("@/layouts/item"),
    meta: {
        title: '特殊功能',
    },
    children: [
        {
            path: "special-fun-list",
            component: () => import("@/pages/parameter/special-fun/special-fun-list"),
            meta: {
                title: "号码隐藏",
                icon: "el-icon-star-off",
            }
        },
        {
            path: "explicit-implicit-control",
            component: () => import("@/pages/parameter/explicit-control/explicit-implicit-control"),
            meta: {
                title: "显隐控制",
                icon: "el-icon-s-help",
            }
        }
    ]
}