<template>
  <el-button :type="type" :size="size" :loading="loading" :icon="icon" v-if="hasPer()" @click="handleClick">
    <slot></slot>
  </el-button>
</template>

<script>
import hasPermission from "../../permission";
/**
 * 权限按钮
 */
export default {
  name: "per-button",
  props: {
    type: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "",
    },
    per: {
      type: String,
      required: true
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
    hasPer() {
      return hasPermission(this.per)
    },
  },
}
</script>

<style scoped>

</style>