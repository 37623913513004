export default
[
    {
        path: "projects-list",
        component: () => import("@/pages/parameter/projects/projects-list"),
        meta: {
            title: "项目列表",
            icon: "el-icon-set-up",
        }
    },
]