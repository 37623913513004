import train from "@/router/tenant-manage/train"   //培训管理
import tenant from "@/router/tenant-manage/tenant"   //培训管理
import view from "@/router/tenant-manage/view"   //培训管理
export default
{
    path: "/tenant-manage",
    component: () => import("@/layouts/item"),
    meta: {
        title: "系统控制台"
    },
    children: [
        train,
        tenant,
        view,
    ]
}

