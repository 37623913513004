import customer from "@/router/work/customer"   //客资管理
import appoint from "@/router/work/appoint"     //预约管理
import order from "@/router/work/order"         //订单管理
import proceeds from "@/router/work/proceeds"   //收款管理
import schedule from "@/router/work/schedule"   //档期管理
import style from "@/router/work/style"         //款式管理
import clothes from "@/router/work/clothes"   //婚纱管理
import refund from "@/router/work/refund"   //退款管理
import share from "@/router/work/share"   //退款管理
import spend from "@/router/work/spend"   //支出管理
import clothesOperation from "@/router/work/clothes-operation"   //出入库管理
import store from "@/router/work/store"   //出入库管理
import purchases from "@/router/work/purchases"   //出入库管理
import account from "@/router/work/account";    //账户管理
export default
    {
        path: "/work",
        component: () => import("@/layouts/item"),
        meta: {
            title: "工作台"
        },
        children: [
            customer,
            appoint,
            order,
            proceeds,
            schedule,
            style,
            clothes,
            refund,
            share,
            spend,
            clothesOperation,
            store,
            purchases,
            account
        ]
    }

