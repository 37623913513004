export default [
    {
        path: "course-list",
        component: () => import("@/pages/train/course/course-list"),
        meta: {
            title: "课程",
            icon: "el-icon-tickets",
        }
    },

]