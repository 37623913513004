export default
{
    path: "proceeds",
    component: () => import("@/layouts/item"),
    meta: {
        title: "收款管理"
    },
    children: [
        {
            path: "add-to-proceeds",
            component: () => import("@/pages/proceeds/add-to-proceeds.vue"),
            meta: {
                title: "线上订单添加"
            }
        },
        {
            path: "proceeds-edit",
            component: () => import("@/pages/proceeds/proceeds-edit"),
            meta: {
                title: "收款编辑"
            }
        },
        {
            path: "proceeds-list",
            component: () => import("@/pages/proceeds/proceeds-list"),
            meta: {
                title: "收款列表",
                icon: "el-icon-document",
            }
        },
        {
            path: "proceeds-project-list",
            component: () => import("@/pages/proceeds/proceeds-project-list.vue"),
            meta: {
                title: "收款项目",
                icon: "el-icon-set-up",
            }
        },
        {
            path: "proceeds-payment-list",
            component: () => import("@/pages/proceeds/proceeds-payment-list.vue"),
            meta: {
                title: "收款方式",
                icon: "el-icon-wallet",
            }
        },
        {
            path: "confirm-proceeds",
            component: () => import("@/pages/proceeds/confirm-proceeds"),
            meta: {
                title: "确认到账"
            }
        },
        {
            path: "direct-add-proceeds",
            component: () => import("@/pages/proceeds/direct-add-proceeds"),
            meta: {
                title: "直接加收款"
            }
        },
    ]
}

