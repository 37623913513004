import serviceReports from "@/router/statistic/service-reports"   //客服报表
import clothesAnalysis from "@/router/statistic/clothes-analysis"   //款式分析表
import shopReports from "@/router/statistic/shop-reports"   //店铺报表
export default
{
    path: "/statistic",
    component: () => import("@/layouts/item"),
    meta: {
        title: "统计报表"
    },
    children: [
        serviceReports,
        clothesAnalysis,
        shopReports,
    ]
}

