import Vue from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios'
import '@/assets/css/base.css'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import commUtils from './common/utils/comm-utils';
import selectUtils from './common/utils/select-utils';
import selectShareUtils from './common/utils/select-share-utils';
import selectSelfUtils from './common/utils/select-self-utils';
import projectsType from './common/constant/projects-type';
import labelType from './common/constant/label-type';
import dateUtils from './common/utils/date-utils';
import upload from "./common/utils/upload";
import VueCropper from 'vue-cropper'
import PerButton from "./components/button/per-button"
import { create, all } from 'mathjs'

//划拽拖动
import Sortable from 'sortablejs';
//md5
import md5 from 'js-md5';
import hasPermission from "./permission/index";


import router from './router/home'

const mathjs = create(all)

mathjs.config({
  number: 'BigNumber',
  precision: 64
})

Vue.config.productionTip = false
Vue.use(VueCropper)
Vue.component("per-button",PerButton)

Vue.use(ElementUI);
Vue.use(VueViewer,{
  defaultOptions: {
    zIndex: 9999,
  } });
axios.interceptors.request.use(
    config => {
      let emp = localStorage.getItem("emp");
      if (emp!==null){
        // config.headers.empId = JSON.parse(emp).id;
        config.headers['empId']=JSON.parse(emp).id;
      }
      let tenantCrop = localStorage.getItem("tenantCrop");
      if (tenantCrop!==null){
        // config.headers.tenantCrop = tenantCrop;
        config.headers['tenantCrop']=tenantCrop;
      }
      return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axios.defaults.baseURL = '/api'
Vue.prototype.$axios = axios;
Vue.prototype.$commUtils = commUtils;
Vue.prototype.$selectUtils = selectUtils;
Vue.prototype.$selectShareUtils = selectShareUtils;
Vue.prototype.$selectSelfUtils = selectSelfUtils;
Vue.prototype.$projectsType = projectsType;
Vue.prototype.$labelType = labelType;
Vue.prototype.$upload = upload;
Vue.prototype.$dateUtils = dateUtils;
Vue.prototype.$per = hasPermission;
Vue.prototype.$sortable = Sortable;
Vue.prototype.$md5 = md5;
Vue.prototype.$math = mathjs

export default  new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
