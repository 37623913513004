import company from "@/router/tenant-manage/train/company"   //培训公司
import course from "@/router/tenant-manage/train/course"   //培训课程
import user from "@/router/tenant-manage/train/user"   //培训用户
import order from "@/router/tenant-manage/train/order"   //培训用户
import proceeds from "@/router/tenant-manage/train/proceeds"   //培训用户
export default
{
    path: "train",
    component: () => import("@/layouts/item"),
    meta: {
        title: "培训管理"
    },
    children: [
        ...company,
        ...course,
        ...user,
        ...order,
        ...proceeds,
    ]
}
