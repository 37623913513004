export  default [
    {
        path: "warning-add",
        component: () => import("@/pages/parameter/warning/warning-add"),
        meta: {
            title: "预警添加"
        }
    },
    {
        path: "warning-edit",
        component: () => import("@/pages/parameter/warning/warning-edit"),
        meta: {
            title: "预警编辑"
        }
    },
    {
        path: "warning-list",
        component: () => import("@/pages/parameter/warning/warning-list"),
        meta: {
            title: "预警列表",
            icon: "el-icon-warning-outline",
        }
    },
]