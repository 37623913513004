<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    window.addEventListener('unload', this.saveState)
  },
  methods: {
    saveState() {
      localStorage.setItem('state', JSON.stringify(this.$store.state))
    }
  }
}
</script>
<style>
@import "./assets/css/main.css";
*{
  margin: 0;
}
html,body,#app{
  height: 100%;
}
</style>
