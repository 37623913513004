export default [
    {
        path: "tenant-mobile-permission",
        component: () => import("@/pages/tenant/tenant-list/tenant-mobile-permission"),
        meta: {
            title: "公司移动端权限"
        }
    },
    {
        path: "tenant-permission",
        component: () => import("@/pages/tenant/tenant-list/tenant-permission"),
        meta: {
            title: "公司电脑端权限"
        }
    },
    {
        path: "tenant-list",
        component: () => import("@/pages/tenant/tenant-list/tenant-list"),
        meta: {
            title: "客户列表",
            icon: "el-icon-office-building",
        }
    },
    {
        path: "tenant-edit",
        component: () => import("@/pages/tenant/tenant-list/tenant-edit"),
        meta: {
            title: "公司编辑"
        }
    },
    {
        path: "tenant-purchase",
        component: () => import("@/pages/tenant/tenant-list/tenant-purchase"),
        meta: {
            title: "公司充值"
        }
    },
    {
        path: "tenant-bill",
        component: () => import("@/pages/tenant/tenant-list/tenant-bill"),
        meta: {
            title: "充值列表",
            icon: "el-icon-s-finance",
        }
    },
]