export default
{
    path: "clothes-analysis",
    component: () => import("@/layouts/item"),
    meta: {
        title: "款式分析表"
    },
    children: [
        {
            path: "clothes-order",
            component: () => import("@/pages/statistic/clothes-analysis/clothes-order"),
            meta: {
                title: "款式订单分析表",
                icon: "el-icon-tickets",
            }
        },
        {
            path: "clothes-order-list",
            component: () => import("@/pages/statistic/clothes-analysis/clothes-order-list"),
            meta: {
                title: "款式订单所有订单",
            }
        },
        {
            path: "clothes-order-page",
            component: () => import("@/pages/statistic/clothes-analysis/clothes-order-page"),
            meta: {
                title: "款式订单分析报表",
                icon: "el-icon-notebook-2",
            }
        },
        {
            path: "clothes-order-same",
            component: () => import("@/pages/statistic/clothes-analysis/clothes-order-same"),
            meta: {
                title: "款式订单报表相同款式"
            }
        },
        {
            path: "clothes-schedule",
            component: () => import("@/pages/statistic/clothes-analysis/clothes-schedule"),
            meta: {
                title: "款式档期分析表",
                icon: "el-icon-c-scale-to-original",
            }
        },
        {
            path: "clothes-schedule-page",
            component: () => import("@/pages/statistic/clothes-analysis/clothes-schedule-page"),
            meta: {
                title: "款式档期分析报表",
                icon: "el-icon-date",
            }
        },
        {
            path: "clothes-schedule-same",
            component: () => import("@/pages/statistic/clothes-analysis/clothes-schedule-same"),
            meta: {
                title: "款式档期分析表相同款式"
            }
        },

    ]
}
