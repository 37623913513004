export default {
    path: "clothes-operation",
    component: () => import("@/layouts/item"),
    children: [
        {
            path: "fill-operation",
            component: () => import("@/pages/clothesOperation/fill-operation/fill-operation"),
            meta: {
                title: "填写调货申请",
                icon: "el-icon-circle-plus-outline",
            }
        },
        {
            path: "operation-rule",
            component: () => import("@/pages/clothesOperation/operation-rule"),
            meta: {
                title: "默认调货周期",
                icon: "el-icon-date",
            }
        },
        {
            path: "operation-list",
            component: () => import("@/pages/clothesOperation/operation-list"),
            meta: {
                title: "调货申请表",
                icon: "el-icon-notebook-2",
            }
        }
    ]
}