export default
{
    path: "schedule",
    component: () => import("@/layouts/item"),
    meta: {
        title: "档期管理"
    },
    children: [
        {
            path: "order-add-schedule",
            component: () => import("@/pages/schedule/order-add-schedule"),
            meta: {
                title: "订单添加档期"
            }
        },
        {
            path: "schedule-list",
            component: () => import("@/pages/schedule/schedule-list"),
            meta: {
                title: "档期列表",
                icon: "el-icon-notebook-1",
            }
        },
    ]
}

