export default
    [
        {
            path: "position-add",
            component: () => import("@/pages/parameter/shop/position-add"),
            meta: {
                title: "位置添加"
            }
        },
        {
            path: "position-edit",
            component: () => import("@/pages/parameter/shop/position-edit"),
            meta: {
                title: "位置编辑"
            }
        },
        {
            path: "position-list",
            component: () => import("@/pages/parameter/shop/position-list"),
            meta: {
                title: "库存位置",
                icon: "el-icon-location-information",
            }
        },
        {
            path: "room-add",
            component: () => import("@/pages/parameter/shop/room-add"),
            meta: {
                title: "房间添加"
            }
        },
        {
            path: "room-edit",
            component: () => import("@/pages/parameter/shop/room-edit"),
            meta: {
                title: "房间编辑"
            }
        },
        {
            path: "room-list",
            component: () => import("@/pages/parameter/shop/room-list"),
            meta: {
                title: "房间参数",
                icon: "el-icon-turn-off",
            }
        },
        {
            path: "shop-add",
            component: () => import("@/pages/parameter/shop/shop-add"),
            meta: {
                title: "店铺添加"
            }
        },
        {
            path: "shop-edit",
            component: () => import("@/pages/parameter/shop/shop-edit"),
            meta: {
                title: "店铺编辑"
            }
        },
        {
            path: "shop-list",
            component: () => import("@/pages/parameter/shop/shop-list"),
            meta: {
                title: "店铺",
                icon: "el-icon-s-shop",
            }
        }
]