import tenantList from "@/router/tenant-manage/tenant/tenant-list"
import log from "@/router/tenant-manage/tenant/log"

export default
{
    path: "tenant",
    component: () => import("@/layouts/item"),
    meta: {
        title: "公司管理"
    },
    children: [
        ...tenantList,
        ...log
    ]
}