export default
{
    path: "share",
    component: () => import("@/layouts/item"),
    meta: {
        title: "云仓"
    },
    children: [
        {
            path: "style-edit-list",
            component: () => import("@/pages/share/edit-list/style-edit-list"),
            meta: {
                title: "款式编辑器列表"
            }
        },
        {
            path: "lib-style-list",
            component: () => import("@/pages/share/library/lib-style-list"),
            meta: {
                title: "品牌款式库"
            }
        },
        {
            path: "lib-brand-list",
            component: () => import("@/pages/share/lib-brand/lib-brand-list"),
            meta: {
                title: "品牌库列表"
            }
        },
        {
            path: "my-lib-brand-list",
            component: () => import("@/pages/share/lib-brand/my-lib-brand-list"),
            meta: {
                title: "品牌列表"
            }
        },
        {
            path: "share-clothes-list",
            component: () => import("@/pages/share/share-clothes/share-clothes-list"),
            meta: {
                title: "分享婚纱列表"
            }
        },
        {
            path: "transport-list-admin",
            component: () => import("@/pages/share/share-clothes/transport/transport-list-admin"),
            meta: {
                title: "调货列表(管理员)"
            }
        },
        {
            path: "transport-list",
            component: () => import("@/pages/share/share-clothes/transport/transport-list"),
            meta: {
                title: "调货列表"
            }
        },
    ]
}

