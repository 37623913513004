export default [
    {
        path: "user-add",
        component: () => import("@/pages/train/user/user-add"),
        meta: {
            title: "员工添加"
        }
    },
    {
        path: "user-edit",
        component: () => import("@/pages/train/user/user-edit"),
        meta: {
            title: "员工编辑"
        }
    },
    {
        path: "user-list",
        component: () => import("@/pages/train/user/user-list"),
        meta: {
            title: "员工",
            icon: "el-icon-user",
        }
    },

]