export default
{
    path: "emp",
    component: () => import("@/layouts/item"),
    meta: {
        title: "用户管理"
    },
    children: [
        {
            path: "emp-manage",
            component: () => import("@/pages/emp/emp/emp-manage"),
            meta: {
                title: "员工管理",
                icon: "el-icon-user",
            }
        },
        {
            path: "external-emp-manage",
            component: () => import("@/pages/emp/emp/external-emp-manage"),
            meta: {
                title: "外部员工管理"
            }
        },
        {
            path: "emp-pwd-update",
            component: () => import("@/pages/emp/emp-pwd-update"),
            meta: {
                title: "密码修改"
            }
        },
        {
            path: "role-manage",
            component: () => import("@/pages/emp/role/role-manage"),
            meta: {
                title: "职位管理",
                icon: "el-icon-menu",
            }
        },
        {
            path: "tenant-info",
            component: () => import("@/pages/emp/tenant-info"),
            meta: {
                title: "企业账户信息",
                icon: "el-icon-more",
            }
        },
        {
            path: "upgrade-log",
            component: () => import("@/pages/emp/upgrade-log"),
            meta: {
                title: "产品日志",
                icon: "el-icon-more-outline",
            }
        }

    ]
}
