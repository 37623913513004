import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)
export default new Vuex.Store({
    state: localStorage.getItem('state') ? JSON.parse(localStorage.getItem('state')):{
        keepAlive: [],
        menus:{},
        routerView:{},
        permission: [],
        openTabs: [],
        activeIndex: "",
        sidebars: {},
    },
    mutations: {
        setKeepAlive: (state, keepAlive) => {
            state.keepAlive = keepAlive
        },
        setMenus:(state, menus)=>{
            state.menus=menus
        },
        setRouterView:(state, routerView)=>{
            state.routerView=routerView
        },
        clearAll(){
            this.state.keepAlive=[];
            this.state.menus={};
            this.state.routerView={};
            this.state.permission = [];
            this.state.openTabs = [];
            this.state.activeIndex = "";
            this.state.sidebars = {};
        },
        setPermission(state, permission) {
            state.permission = permission
        },
        addTab(state, tab) {
            //如果标签重复,则不添加进去
            let size=state.openTabs.filter(k=>k.label===tab.label);
            if (size.length>0) return;
            state.openTabs.push(tab)
        },
        deleteTab(state, tab) {
            let index = 0;
            for (let option of state.openTabs) {
                if (option.name === tab) {
                    break;
                }
                index++;
            }
            this.state.openTabs.splice(index, 1);
        },
        setActiveIndex(state, index) {
            state.activeIndex = index
        },
        setSidebars(state, sidebars) {
            state.sidebars =  sidebars
        }
    },
    getters: {
        keepAlive: state => state.keepAlive,
        openTabs(state) {
            return state.openTabs
        }
    }
});