export default [
    {
        path: "order-add",
        component: () => import("@/pages/train/order/order-add"),
        meta: {
            title: "订单添加"
        }
    },
    {
        path: "order-edit",
        component: () => import("@/pages/train/order/order-edit"),
        meta: {
            title: "订单编辑"
        }
    },
    {
        path: "order-list",
        component: () => import("@/pages/train/order/order-list"),
        meta: {
            title: "培训订单",
            icon: "el-icon-s-order",
        }
    },

]