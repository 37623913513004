export default
[
    {
        path: "source-add",
        component: () => import("@/pages/parameter/source/source-add"),
        meta: {
            title: "渠道添加"
        }
    },
    {
        path: "source-edit",
        component: () => import("@/pages/parameter/source/source-add"),
        meta: {
            title: "渠道编辑"
        }
    },
    {
        path: "source-list",
        component: () => import("@/pages/parameter/source/source-list"),
        meta: {
            title: "渠道来源",
            icon: "el-icon-attract",
        }
    },
]