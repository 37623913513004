export default
{
    path: "store",
    component: () => import("@/layouts/item"),
    meta: {
        title: "商城"
    },
    children: [
        {
            path: "brand-list",
            component: () => import("@/pages/store/brand-list/brand-list"),
            meta: {
                title: "品牌列表"
            }
        },
        {
            path: "store-style-add",
            component: () => import("@/pages/store/list/store-style-add"),
            meta: {
                title: "添加到库"
            }
        },
        {
            path: "store-series-list",
            component: () => import("@/pages/store/store-series/store-series-list"),
            meta: {
                title: "款式系列列表"
            }
        },
        {
            path: "style-store-list",
            component: () => import("@/pages/store/style-store/style-store-list"),
            meta: {
                title: "贸易款式"
            }
        },
        {
            path: "store-order-list",
            component: () => import("@/pages/purchases/order/store-order-list"),
            meta: {
                title: "采购列表"
            }
        },
        {
            path: "store-order-admin-list",
            component: () => import("@/pages/store/order/store-order-admin-list"),
            meta: {
                title: "贸易订单列表(经销商)"
            }
        },
        {
            path: "customer-list",
            component: () => import("@/pages/store/customer/customer-list"),
            meta: {
                title: "客户列表"
            }
        },
    ]
}

