export default [
    {
        path: "company-list",
        component: () => import("@/pages/train/company/company-list"),
        meta: {
            title: "客户",
            icon: "el-icon-user-solid",
        }
    },

]