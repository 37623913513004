export default
{
    path: "spend",
    component: () => import("@/layouts/item"),
    meta: {
        title: "支出管理"
    },
    children: [
        {
            path: "spend-direct",
            component: () => import("@/pages/spend/spend/spend-direct"),
            meta: {
                title: "直接添加支出"
            }
        },
        {
            path: "declare-list",
            component: () => import("@/pages/spend/declare/declare-list"),
            meta: {
                title: "申报列表"
            }
        },
        {
            path: "spend-category-list",
            component: () => import("@/pages/parameter/spend/spend-category-list"),
            meta: {
                title: "支出项目类目"
            }
        },
        {
            path: "spend-item-list",
            component: () => import("@/pages/parameter/spend/spend-item-list"),
            meta: {
                title: "支出项目"
            }
        },
        {
            path: "spend-method-list",
            component: () => import("@/pages/parameter/spend/spend-method-list"),
            meta: {
                title: "报销收款参数"
            }
        },
        {
            path: "declare-add",
            component: () => import("@/pages/spend/declare/declare-add"),
            meta: {
                title: "填写申报表"
            }
        },
        {
            path: "examine-list",
            component: () => import("@/pages/spend/examine-list"),
            meta: {
                title: "审核列表"
            }
        },
        {
            path: "settlement-list",
            component: () => import("@/pages/spend/settlement-list"),
            meta: {
                title: "结算列表"
            }
        },
        {
            path: "spend-list",
            component: () => import('@/pages/spend/spend/spend-list'),
            meta: {
                title: "支出列表"
            }
        },
        {
            path: "declare-payment",
            component: () => import('@/pages/spend/declare/declare-payment-list'),
            meta: {
                title: "支付申报"
            }
        }
    ]
}