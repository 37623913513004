export default
{
    path: "appoint",
    component: () => import("@/layouts/item"),
    meta: {
        title: "预约管理"
    },
    children: [
        {
            path: "app-arrival",
            component: () => import("@/pages/appoint/list/app-arrival"),
            meta: {
                title: "预约到店"
            }
        },
        {
            path: "app-edit",
            component: () => import("@/pages/appoint/list/app-edit"),
            meta: {
                title: "预约编辑"
            }
        },
        {
            path: "app-list",
            component: () => import("@/pages/appoint/list/app-list"),
            meta: {
                title: "预约列表",
                icon: "el-icon-tickets",
            }
        },
        {
            path: "app-add",
            component: () => import("@/pages/appoint/app-add"),
            meta: {
                title: "预约添加"
            }
        },
        {
            path: "app-after-list",
            component: () => import("@/pages/appoint/app-after-list"),
            meta: {
                title: "售后预约列表"
            }
        },
        {
            path: "app-schedule-list",
            component: () => import("@/pages/appoint/app-schedule-list"),
            meta: {
                title: "每日预约",
                icon: "el-icon-date",
            }
        },
        {
            path: "app-projects-list",
            component: () => import("@/pages/parameter/projects/app-projects-list.vue"),
            meta: {
                title: "预约项目",
                icon: "el-icon-turn-off",
            }
        },
    ]
}
