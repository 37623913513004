export default
[
    {
        path: "discount-add",
        component: () => import("@/pages/parameter/discount/discount-add"),
        meta: {
            title: "折扣添加"
        }
    },
    {
        path: "discount-edit",
        component: () => import("@/pages/parameter/discount/discount-edit"),
        meta: {
            title: "折扣编辑"
        }
    },
    {
        path: "discount-list",
        component: () => import("@/pages/parameter/discount/discount-list"),
        meta: {
            title: "折扣参数",
            icon: "el-icon-s-marketing",
        }
    },
]