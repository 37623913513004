export default [
    {
        path: "pc-view-list",
        component: () => import("@/pages/view/pc-view/pc-view-list"),
        meta: {
            title: "电脑页面列表",
            icon: "el-icon-monitor",
        }
    },
    {
        path: "pc-button-list",
        component: () => import("@/pages/view/pc-view/pc-button-list"),
        meta: {
            title: "电脑按钮列表",
            icon: "el-icon-s-platform",
        }
    },
    {
        path: 'product-grade-list',
        component: () => import("@/pages/view/permission/product-grade/product-grade-list"),
        meta: {
            title: "产品等级管理",
            icon: "el-icon-setting",
        }
    }

]