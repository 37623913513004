export default
{
    path: "service-reports",
    component: () => import("@/layouts/item"),
    meta: {
        title: "客服报表"
    },
    children: [
        {
            path: "customer-source",
            component: () => import("@/pages/statistic/service-reports/customer-source"),
            meta: {
                title: "渠道分析表",
                icon: "el-icon-data-board"
            }
        },
        {
            path: "service-analysis",
            component: () => import("@/pages/statistic/service-reports/service-analysis"),
            meta: {
                title: "客服分析表",
                icon: "el-icon-pie-chart"
            }
        },
    ]
}
