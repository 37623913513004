export default
{
    path: "shop-reports",
    component: () => import("@/layouts/item"),
    meta: {
        title: "店铺报表"
    },
    children: [
        {
            path: "proceeds-analysis",
            component: () => import("@/pages/statistic/shop-reports/proceeds-analysis"),
            meta: {
                title: "收款分析表",
                icon: "el-icon-pie-chart",
            }
        },
        {
            path: "spend-analysis",
            component: () => import("@/pages/statistic/shop-reports/spend-analysis"),
            meta: {
                title: "支出分析表",
                icon: "el-icon-s-data",
            }
        },
        {
            path: "shop-earning-reports",
            component: () => import("@/pages/statistic/shop-reports/shop-earning-reports"),
            meta: {
                title: "店铺业绩分析表",
                icon: "el-icon-s-marketing",
            }
        },
        {
            path: "warning-order-analysis",
            component: () => import("@/pages/statistic/shop-reports/warning-order-analysis"),
            meta: {
                title: "售后执行分析表",
                icon: "el-icon-shopping-cart-full",
            }
        },
        {
            path: "train-proceeds-analysis",
            component: () => import("@/pages/statistic/shop-reports/train-proceeds-analysis"),
            meta: {
                title: "培训收款分析表",
                icon: "el-icon-pie-chart",
            }
        },

    ]
}
