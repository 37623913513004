export default
[
    {
        path: "operation-add",
        component: () => import("@/pages/parameter/operation/operation-add"),
        meta: {
            title: "婚纱操作参数添加"
        }
    },
    {
        path: "operation-edit",
        component: () => import("@/pages/parameter/operation/operation-edit"),
        meta: {
            title: "婚纱操作参数编辑"
        }
    },
    {
        path: "operation-list",
        component: () => import("@/pages/parameter/operation/operation-list"),
        meta: {
            title: "婚纱操作参数",
            icon: "el-icon-s-operation",
        }
    },
]