import self from "@/main";
export default {
    /**深拷贝*/
    objDeepCopy(source){
        if(typeof source === 'object'){
            var sourceCopy = source instanceof Array ? [] :{}
            for (var item in source){
                if (!source[item]) {
                    sourceCopy[item] = source[item]
                } else {
                    sourceCopy[item] = typeof source[item] === 'object' ? objDeepCopy(source[item]) : source[item]
                }
            }
            return sourceCopy
        }
        return source
    },
//    获取ModuleId
    queryAnnotation(fullPath){
        let pathArray=fullPath.split("/");

        let menus=self.$store.state.routerView.moduleList.filter(k=>k.url==="/"+pathArray[1])[0].child;

        for(let k of menus){
            for (let v of k.child){
                if (v.url===fullPath){
                    return self.$axios({
                        method: "GET",
                        url: "/annotation/queryByModuleId",
                        params: {
                            moduleId:v.id
                        }
                    });
                }
            }
        }

    }
}
