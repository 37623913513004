export default
{
    path: "order",
    component: () => import("@/layouts/item"),
    meta: {
        title: "订单管理"
    },
    children: [
        {
            path: "online-order-add",
            component: () => import("@/pages/order/online-order-add"),
            meta: {
                title: "线上订单添加"
            }
        },
        {
            path: "add-order",
            component: () => import("@/pages/order/add-order"),
            meta: {
                title: "快速添加订单",
                icon: "el-icon-circle-plus-outline",
            }
        },
        {
            path: "order-appoint",
            component: () => import("@/pages/order/order-appoint"),
            meta: {
                title: "订单绑定预约"
            }
        },
        {
            path: "order-images",
            component: () => import("@/pages/order/order-images"),
            meta: {
                title: "订单图片"
            }
        },
        {
            path: "order-list",
            component: () => import("@/pages/order/order-list"),
            meta: {
                title: "订单列表",
                icon: "el-icon-notebook-2",
            }
        },
        {
            path: "order-update",
            component: () => import("@/pages/order/order-update"),
            meta: {
                title: "订单修改"
            }
        },
        {
            path: "order-projects-list",
            component: () => import("@/pages/parameter/projects/order-projects-list"),
            meta: {
                title: "订单项目",
                icon: "el-icon-open",
            }
        },
    ]
}

