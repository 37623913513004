export default
[
    {
        path: "state-list",
        component: () => import("@/pages/parameter/state/state-list"),
        meta: {
            title: "状态列表",
            icon: "el-icon-suitcase",
        }
    },
]