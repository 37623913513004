export default
{
    path: "style",
    component: () => import("@/layouts/item"),
    meta: {
        title: "款式管理"
    },
    children: [
        {
            path: "style-image",
            component: () => import("@/pages/style/style-image"),
            meta: {
                title: "款式图片"
            }
        },
        {
            path: "style-list",
            component: () => import("@/pages/style/style-list"),
            meta: {
                title: "款式列表",
                icon: "el-icon-tickets",
            }
        },
        {
            path: "brand-list",
            component: () => import("@/pages/style/brand/brand-list"),
            meta: {
                title: "品牌列表",
                icon: "el-icon-shopping-bag-2",
            }
        },
        {
            path: "brand-grade-list",
            component: () => import("@/pages/style/brand/brand-grade-list"),
            meta: {
                title: "品牌等级列表"
            }
        },
        {
            path: "style-type-list",
            component: () => import("@/pages/style/style-type/style-type-list"),
            meta: {
                title: "款式类型",
                icon: "el-icon-open",
            }
        },
        {
            path: "label-list",
            component: () => import("@/pages/parameter/label/label-list"),
            meta: {
                title: "款式标签",
                icon: "el-icon-collection-tag",
            }
        },
        {
            path: "style-add",
            component: () => import("@/pages/style/style-add"),
            meta: {
                title: "款式添加",
                icon: "el-icon-zoom-in",
            }
        },


    ]
}

