import mobileView from "@/router/tenant-manage/view/mobile-view"   //培训公司
import pcView from "@/router/tenant-manage/view/pc-view"   //培训公司
import defaultPermission from "@/router/tenant-manage/view/default-permission"
import functionalAnnotation from "@/router/tenant-manage/view/functional-annotation"

export default
{
    path: "view",
    component: () => import("@/layouts/item"),
    meta: {
        title: "参数管理"
    },
    children: [
        ...mobileView,
        ...pcView,
        ...defaultPermission,
        ...functionalAnnotation
    ]
}
