export default [
    {
        path: "proceeds-add",
        component: () => import("@/pages/train/proceeds/proceeds-add"),
        meta: {
            title: "收款添加"
        }
    },
    {
        path: "proceeds-edit",
        component: () => import("@/pages/train/proceeds/proceeds-edit"),
        meta: {
            title: "收款编辑"
        }
    },
    {
        path: "proceeds-list",
        component: () => import("@/pages/train/proceeds/proceeds-list"),
        meta: {
            title: "收款列表",
            icon: "el-icon-bank-card",
        }
    },

]